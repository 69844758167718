/**
 * Export `assignRef`.
 */

export function assignRef<T = any>(ref: any, value: T) {
  if (!ref) {
    return;
  }

  try {
    if (typeof ref === 'function') {
      ref(value);
    } else {
      ref.current = value;
    }
  } catch (error) {
    throw new Error('cannot_assign_ref');
  }
}

/**
 * Export `assignRefs`.
 */

export function assignRefs<T>(...refs: any[]) {
  return (value: T) => refs.forEach(ref => assignRef(ref, value));
}
