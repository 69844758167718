/**
 * Module dependencies.
 */

import { AxiosError } from 'axios';
import { Button } from 'src/components/core/button';
import {
  Description,
  Form,
  Link,
  Title
} from 'src/components/layout/authentication/styles';

import { GetStaticProps, NextPage } from 'next';
import { InputField } from 'src/components/core/forms/input/field';
import { LoginData, login } from 'src/api/authentication/login';
import { PasswordField } from 'src/components/core/forms/input/password-field';
import { SEO } from 'src/components/core/seo';
import { SSGProps } from 'src/types/app';
import { Trans, useTranslation } from 'next-i18next';
import { getNetworkErrorTranslatedKey } from 'src/core/utils/requests';
import { inputMasks } from 'src/core/constants/forms';
import { media } from '@untile/react-core/styles/media';
import { requiredRule } from 'src/core/utils/fields-rules';
import { routes } from 'src/core/constants/routes';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { textStyles } from 'src/styles/typography';
import { toast } from 'src/providers/toast';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `RecoverPasswordLink` styled component.
 */

const RecoverPasswordLink = styled(Link)`
  ${textStyles.paragraph3}

  font-weight: 400;
  margin-bottom: 16px;
  padding-left: 16px;

  ${media.min.ms`
    margin-bottom: 24px;
  `}
`;

/**
 * `Home` page.
 */

const Home: NextPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<LoginData>({ mode: 'onSubmit' });

  const { isLoading, mutate } = useMutation(login);
  const onSubmit = useCallback(
    (data: LoginData) => {
      mutate(data, {
        onError: networkError => {
          const error = networkError as AxiosError;
          const errorMessage = getNetworkErrorTranslatedKey({
            basePath: 'sign-in:',
            error
          });

          toast.error(t(errorMessage, { returnObjects: true }), {
            toastId: 'signInServerError'
          });

          if (error?.response?.status === 401) {
            setError('root.serverError', { type: '401' });
          }
        },
        onSuccess: () => {
          router.push(routes.dashboard);
        }
      });
    },
    [mutate, router, setError, t]
  );

  useEffect(() => {
    if (router?.query?.session === 'expired') {
      toast.error(
        { title: t('common:auth.sessionExpired') },
        { toastId: 'sessionExpired' }
      );

      router.replace(router?.pathname, undefined, { shallow: true });
    }
  }, [router, t]);

  return (
    <>
      <SEO title={t('sign-in:metatags.title')} />

      <Title>{t('sign-in:title')}</Title>

      <Description>
        <Trans
          components={[
            <Link href={routes.authentication.signUp} key={'register-link'} />
          ]}
          i18nKey={'sign-in:description'}
          transSupportBasicHtmlNodes
        />
      </Description>

      <Form autoComplete={'off'} onSubmit={handleSubmit(onSubmit)}>
        <InputField
          control={control}
          disabled={isSubmitting}
          hasError={!isEmpty(errors?.root?.serverError)}
          label={t('auth:form.labels.nif')}
          mask={inputMasks.nif}
          name={'nif'}
          rules={requiredRule(t)}
        />

        <PasswordField
          control={control}
          disabled={isSubmitting}
          hasError={!isEmpty(errors?.root?.serverError)}
          label={t('auth:form.labels.password')}
          name={'password'}
          rules={requiredRule(t)}
        />

        <RecoverPasswordLink href={routes.authentication.recoverPassword}>
          {t('sign-in:actions.forgotPassword')}
        </RecoverPasswordLink>

        <Button isLoading={isSubmitting || isLoading} stretch type={'submit'}>
          {t('sign-in:actions.submit')}
        </Button>
      </Form>
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSGProps> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        'common',
        'auth',
        'sign-in'
      ])),
      layout: 'auth'
    },
    revalidate: 60
  };
};

/**
 * Export `Home` page.
 */

export default Home;
