/**
 * Module dependencies.
 */

import { User } from 'src/types/user';
import { axiosInstance, getApiEndpoint } from 'src/core/utils/requests';
import { cleanValue } from 'src/core/utils/strings';

/**
 * Export `LoginData` type.
 */

export type LoginData = {
  nif: User['nif'];
  password: string;
};

/**
 * Export `login` request.
 */

export async function login(formData: LoginData) {
  const response = await axiosInstance.post<Record<'data', { token: string }>>(
    getApiEndpoint('signIn'),
    { ...formData, nif: cleanValue(formData.nif) }
  );

  return response?.data;
}
