/**
 * Module dependencies.
 */

import { ComponentPropsWithRef, ReactNode } from 'react';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { getClickProps } from 'src/core/utils/links';
import { ifProp } from 'styled-tools';
import styled from 'styled-components';

/**
 * Export `FieldProps` props
 */

export type FieldProps = {
  children: ReactNode;
  className?: string;
  disabled: boolean;
  error?: string;
  hasError?: boolean;
  hasValue: boolean;
  helpText?: string;
  icon?: string;
  id: string;
  label?: string;
  noFloatingLabel?: boolean;
  onIconClick?: () => void;
  wrapperId?: string;
};

/**
 * `FloatingLabelProps` props
 */

type FloatingLabelProps = ComponentPropsWithRef<'label'> & {
  children: ReactNode;
  hasIcon?: boolean;
  isFloating?: boolean;
};

/**
 * `FieldsetProps` props
 */

type FieldsetProps = Pick<FieldProps, 'icon' | 'hasError' | 'hasValue'>;

/**
 * Export `FormGrid` styled component.
 */

export const FormGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled.label`
  color: var(--form-field-label-color);
  font-size: 10px;
  font-weight: 700;
  grid-area: label;
  padding: calc(var(--form-field-padding-v) / 2) var(--form-field-padding-h) 0;
  transition: color var(--transition-default);
`;

/**
 * Export `LabelText` styled component.
 *
 * This enables the label to float above the input field while the backdrop
 * is clickable to focus on the input.
 */

export const LabelText = styled.span`
  display: block;
  transform: translateY(var(--form-field-label-top));
  transition: transform var(--transition-default);
`;

/**
 * `activeFloatingVars` constant.
 */

const activeFloatingVars = `
  --form-field-label-top: 0;
  --form-field-label-font-size: 10px;
`;

/**
 * Export `FloatingLabelStyle` styled component.
 */

export const FloatingLabelStyle = styled(Label)`
  cursor: text;
  font-size: var(--form-field-label-font-size);
  grid-area: label;
  position: relative;
  transition: var(--transition-default);
  transition-property: color, font-size;
  width: 100%;

  ${ifProp('isFloating', activeFloatingVars)}
  ${ifProp(
    'hasIcon',
    `
    padding-right: var(--form-field-content-padding-right);
  `
  )}
`;

/**
 * Export `InlineLabel` styled component.
 *
 * Used between form fields to separate content.
 */

export const InlineLabel = styled(FloatingLabelStyle)`
  color: var(--color-grey800);
  display: block;
`;

/**
 * Export `FloatingLabel` component.
 */

export function FloatingLabel({ children, ...props }: FloatingLabelProps) {
  return (
    <FloatingLabelStyle {...props}>
      <LabelText>{children}</LabelText>
    </FloatingLabelStyle>
  );
}

/**
 * `BorderWrapper` styled component.
 */

const BorderWrapper = styled.div<{ noLabel: boolean }>`
  background-color: var(--form-field-background-color);
  border: 1px solid var(--form-field-border-color);
  border-radius: var(--form-field-border-radius);
  display: grid;
  grid-template-areas: 'label' 'input';
  grid-template-columns: 1fr min-content;
  min-height: var(--form-field-min-height);
  overflow: hidden;
  position: relative;
  transition: var(--transition-default);
  transition-property: background-color, border-color;

  ${ifProp(
    'noLabel',
    `
    grid-template-areas: 'input' 'input';
  `
  )}
`;

/**
 * Export `Fieldset` styled component.
 */

export const Fieldset = styled.fieldset<FieldsetProps>`
  --form-field-label-font-size: ${ifProp('hasValue', 10, 16)}px;
  --form-field-content-padding-right: calc(
    var(--form-field-padding-h) * ${ifProp('icon', 4, 1)}
  );
  --form-field-placeholder-color: ${ifProp(
    'noFloatingLabel',
    'var(--color-grey600)',
    'transparent'
  )};

  border: none;
  display: grid;
  grid-template-areas: 'input' 'footer';
  grid-template-rows: auto 1fr auto;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  ${ifProp(
    'hasError',
    `
    --form-field-border-color: var(--input-error-color);
  `,
    `
      &:not([disabled]) {
      &:hover {
        --form-field-border-color: var(--color-grey600);
      }

      &:focus-within {
        --form-field-border-color: var(--color-blue500);
      }
    }
  `
  )}

  &:focus-within {
    ${activeFloatingVars}
  }

  &[disabled] {
    --form-field-background-color: var(--color-grey200);
    --form-field-border-color: var(--color-grey400);
  }
`;

/**
 * Export `HelpText` styled component.
 */

export const HelpText = styled(Text).attrs({
  variant: 'paragraph3'
})`
  color: var(--color-grey700);
  display: block;
  padding: var(--form-field-padding-v) var(--form-field-padding-h);
`;

/**
 * Export `ErrorMessage` styled component.
 */

export const ErrorMessage = styled(HelpText).attrs({
  'aria-live': 'assertive',
  role: 'alert'
})`
  color: var(--input-error-color);
`;

/**
 * Export `IconButton` styled component.
 */

export const IconButton = styled.div<{ onClick: any }>`
  align-items: center;
  align-self: center;
  appearance: none;
  background: none;
  border: none;
  color: var(--form-field-label-color);
  display: flex;
  height: calc(var(--form-field-padding-h) * 3);
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: calc(var(--form-field-padding-h) / 2);
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transition-default);
  transition-property: background-color, color;
  width: calc(var(--form-field-padding-h) * 3);

  ${ifProp(
    'onClick',
    `
      cursor: pointer;
      pointer-events: all;

      :focus,
      :hover {
        background-color: var(--color-grey100);
        border-radius: var(--form-field-border-radius);
        color: var(--color-grey800);
        outline: none;
      }
    }
  `
  )}
`;

/**
 * Export `Field` component.
 */

export function Field(props: FieldProps) {
  const {
    children,
    error,
    hasError,
    helpText,
    icon,
    id,
    label,
    onIconClick,
    wrapperId,
    ...rest
  } = props;

  return (
    <Fieldset
      hasError={!!error || !!hasError}
      icon={icon}
      id={wrapperId}
      {...rest}
    >
      <BorderWrapper noLabel={!!rest?.noFloatingLabel}>
        {label && !rest?.noFloatingLabel && (
          <FloatingLabel
            hasIcon={!!icon}
            htmlFor={id}
            isFloating={!!rest.hasValue}
          >
            {label}
          </FloatingLabel>
        )}

        {children}

        {icon && (
          <IconButton {...getClickProps(onIconClick)} onClick={onIconClick}>
            <Svg icon={icon} size={'24px'} />
          </IconButton>
        )}
      </BorderWrapper>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!error && helpText && <HelpText>{helpText}</HelpText>}
    </Fieldset>
  );
}
