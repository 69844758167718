/**
 * Module dependencies
 */

import { TFunction } from 'next-i18next';
import { regexes } from 'src/core/constants/regexes';

/**
 * Export `requiredRule`.
 */

export const requiredRule = (translate: TFunction) => ({
  required: translate('common:form.errors.required')
});

/**
 * Export `certificateCodeRules`.
 */

export const certificateCodeRules = (translate: TFunction) => ({
  ...requiredRule(translate),
  pattern: {
    message: translate('common:form.errors.nifFormat'),
    value: regexes.certificateCode
  }
});

/**
 * Export `emailRules`.
 */

export const emailRules = (translate: TFunction) => ({
  ...requiredRule(translate),
  pattern: {
    message: translate('common:form.errors.emailFormat'),
    value: regexes.email
  }
});

/**
 * Export `nifRules`.
 */

export const nifRules = (translate: TFunction) => ({
  ...requiredRule(translate),
  pattern: {
    message: translate('common:form.errors.nifFormat'),
    value: regexes.nif
  }
});

/**
 * Export `passwordRules`.
 */

export const passwordRules = (translate: TFunction) => ({
  ...requiredRule(translate),
  pattern: {
    message: translate('common:form.errors.passwordFormat'),
    value: regexes.password
  }
});

/**
 * Export `repeatFieldValueRule`.
 */

export const repeatFieldValueRule = (
  translate: TFunction,
  getValue: () => string,
  getState: () => any,
  error: string
) => ({
  ...requiredRule(translate),
  validate: (value: string) => {
    const original = getValue();
    const { isTouched } = getState();

    return !isTouched || original === value || translate(error);
  }
});
