/**
 * Module dependencies.
 */

import { RouterLink } from 'src/components/core/links/router-link';
import { Text } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import styled from 'styled-components';

/**
 * Export `Title` styled component.
 */

export const Title = styled(Text).attrs({
  as: 'h3',
  variant: 'display3'
})`
  color: var(--text-color);
  text-transform: uppercase;

  ${media.min.ms`
    margin-bottom: 16px;
  `}
`;

/**
 * Export `Description` styled component.
 */

export const Description = styled(Text).attrs({
  as: 'p',
  variant: 'paragraph1'
})`
  margin-bottom: 24px;

  ${media.min.ms`
    margin-bottom: 40px;
  `}
`;

/**
 * Export `Link` styled component.
 */

export const Link = styled(RouterLink)`
  color: var(--color-blue500);
  font-weight: 700;
  outline: none;
  text-decoration: none;
  transition: color var(--transition-default);
  width: max-content;

  :focus,
  :focus-within,
  :hover {
    color: var(--color-blue600);
    text-decoration: underline;
  }
`;

/**
 * Export `Form` styled component.
 */

export const Form = styled.form`
  display: grid;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
`;
