/**
 * Module dependencies.
 */

import { Field, FieldProps } from 'src/components/core/forms/styles';
import { Ref, forwardRef, useMemo } from 'react';
import { ifProp } from 'styled-tools';
import { textStyles } from 'src/styles/typography';
import InputMask from 'react-input-mask';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `InputProps` interface.
 */

export type InputProps = React.HTMLProps<HTMLInputElement> &
  Omit<FieldProps, 'children' | 'hasValue'> & {
    as?: 'input' | 'textarea';
    error?: string;
    hasError?: boolean;
    mask?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    ref?: Ref<HTMLInputElement>;
  };

/**
 * `StyledInput` styled component.
 */

const StyledInput = styled.input<{ hasError: boolean }>`
  ${textStyles.paragraph2}

  background: none;
  border: none;
  font-weight: 400;
  grid-area: input;
  margin: 0;
  padding: 0 var(--form-field-content-padding-right)
    calc(var(--form-field-padding-v) * 1.5) var(--form-field-padding-h);
  transition: color var(--transition-default);
  width: 100%;

  &::-ms-input-placeholder {
    color: var(--form-field-placeholder-color);
    font-weight: 700;
  }

  &:-ms-input-placeholder {
    color: var(--form-field-placeholder-color);
    font-weight: 700;
  }

  &::placeholder {
    color: var(--form-field-placeholder-color);
    font-weight: 700;
  }

  :focus {
    outline: none;
  }

  ::-ms-reveal {
    display: none;
  }

  ${ifProp(
    'placeholder',
    `
    padding-top: calc(var(--form-field-padding-v) * 1.5);
  `
  )};

  ${ifProp(
    { as: 'textarea' },
    `
    height: auto;
    resize: vertical;
  `
  )}
`;

/**
 * Export `Input` component.
 */

export const Input = forwardRef((props: InputProps, ref: any) => {
  const {
    className,
    disabled,
    error,
    hasError,
    helpText,
    icon,
    id,
    label,
    mask,
    onIconClick,
    type,
    value,
    ...rest
  } = props;

  const maskInputProps = useMemo(
    () =>
      mask
        ? {
            mask,
            maskPlaceholder: null
          }
        : {},
    [mask]
  );

  return (
    <Field
      className={className}
      disabled={!!disabled}
      error={error}
      hasError={hasError}
      hasValue={!isEmpty(value)}
      helpText={helpText}
      icon={icon}
      id={id}
      label={label}
      noFloatingLabel={!!rest?.placeholder}
      onIconClick={onIconClick}
    >
      <StyledInput
        {...maskInputProps}
        as={mask ? InputMask : undefined}
        disabled={!!disabled}
        hasError={!isEmpty(error)}
        id={id}
        ref={ref}
        type={type ?? 'text'}
        value={value}
        {...rest}
      />
    </Field>
  );
});

/**
 * `Input` display name.
 */

Input.displayName = 'Input';
