/**
 * Module dependencies.
 */

import { InputField, InputFieldProps } from './field';
import { forwardRef, useState } from 'react';
import eyeOffSvg from 'src/assets/24/eye-off.svg';
import eyeOnSvg from 'src/assets/24/eye-empty.svg';

/**
 * `Props` type.
 */

type Props = Omit<InputFieldProps, 'icon' | 'onIconClick' | 'type'>;

/**
 * Export `PasswordField` component.
 */

export const PasswordField = forwardRef((props: Props, ref: any) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <InputField
      {...props}
      icon={showPassword ? eyeOnSvg : eyeOffSvg}
      onIconClick={() => setShowPassword(!showPassword)}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
    />
  );
});

/**
 * `PasswordField` display name.
 */

PasswordField.displayName = 'PasswordField';
